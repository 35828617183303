/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Input-number-to-string'

// Vue@Properties
const props = {
  placeholder: String,
  attributes: Object,
  onChange: Function,
  disabled: Boolean,
  property: String,
  unit: String
}
// Vue@Properties

// Computed methods
const computed = {

  // Func@inputVal
  /**
   * Get number and set value (number+unit)
   * @type {Object}
   */
  inputVal: {
    // getter
    get () {
      return parseInt(this.attributes[this.property], 10) || ''
    },
    // setter
    set (newValue) {
      const value = newValue ? `${newValue}${this.unit}` : newValue
      this.$set(this.attributes, this.property, value)
    }
  }
  // Func@inputVal
}

// Vue component syntax
export default {
  name,
  props,
  computed
}
